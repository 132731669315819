
html, body {
    height: 100%; 
    overflow: hidden; 
    border: none;
    width: 100%;
    margin: 0;
    padding: 0;
}

body {
    background-color: black; /* Change this to the color that suits your video */
}


.App {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    object-fit: fill;
    justify-content: center;
    align-items: center;
}

.yellowBox {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #e1bf00;
    border-bottom: #000000;
    
/* Add this line to ensure the box appears above other elements */
}

.transparent-box {
    background-color: rgba(227, 227, 227, 0.1); 
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1% 5% 1% 5%;
    transition: background-color 0.3s ease;
    height: 70vh;
    width: 60vw;
    backdrop-filter: blur(30px);
    border: 0.5px solid #ffffff48;;
    
    }
    
.pageTitle {
    position: absolute;
    top: 0px;
    color: white;
    font-size: 3em;
    z-index: 10;
    width: 100%;
    text-align: center;
}


.pageSubTitle {
    font-family: font1;
    position: absolute;
    top: 50px;

    color: rgb(0, 0, 0);
    font-size: 3em;
    z-index: 10;
    width: 50%;
    text-align: center;
}

.topRightButton {
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 40px;
    color: Black;
    background-color: #FFFFFF; 
    /* border: 2px solid #ffffff48;; */
    border: none;
    cursor: pointer;
    z-index: 10;
    user-select: none;
    font-family: font1;
    height: 75px;;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; 
    border: 1px solid #d6d5d5;;
    border-radius: 20px;
    
}   


    
.topRightButton:hover {
    border: 1px solid #7a7979;;
    
}
    

.button1 {
    background-color: #FFFFFF; 
    /* border: 2px solid #ffffff48; */
    user-select: none;
    font-size: 30px;
    position: absolute;
    bottom: 300px; /* specify where you want it, e.g., 50px */
    width: 225px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; 
    font-family: font1;
    border: 1px solid #d6d5d5;;
    border-radius: 20px;
    padding-left: 10px;

}


.button1:hover {
    border: 1px solid #7a7979;;
    
}

.video {
    position: fixed;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 110%; /* Scaling up to hide the blur halo */
    height: 110%; /* Scaling up to hide the blur halo */
    filter: blur(20px);
    transform: translate(-5%, -5%);
    border: 10px solid black;
}

.container {
    height: 10px;
    display: flex;
    align-items: center;
    display: flex;
    justify-content: center;
  }

.container:hover {
    color: rgb(255, 255, 0)
    }

.unselectable {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none;
    }
      
.chromeExtension {
    width: 300px;
    height: 225px;
    background-color: #1e1e1e00 ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 5px 5px;
    border: 2px solid #000000;;
    }

.heading {
    color: #000000;
    }

.textBox  {
    width: 250px;
    height: 50px;
    background-color: #00000000;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    border: 2px solid #000000;
    font-weight: bold;
    }

.button {
    background-color: #33333300;
    color: #000000;
    margin: 20px;
    padding: 10px 30px 10px 30px;
    border-radius: 4px;
    border: 2px solid #000000;;
    font-size: 1rem;
    }

.google-signin-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 10px 20px;
    background-color: #33333300;
    border: 0.5px solid #ffffff48;;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
    }
    
.google-signin-btn:hover {
background: #858585;

}

/* .App {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    object-fit: fill;
    justify-content: center;
    align-items: center;
} */
    
.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}



@font-face {
    font-family: 'font1';
    src: url('./assets/font1.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
  }
  


  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust the height to your liking */
  }
  
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #007bff; /* Change the color as needed */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .centered-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 400px;
    width: 400px;
  }
  